// 英姿态提供的接口服务
import request from 'utils/req';
// 获取授权
export const getToken = (params) => {
  return request({
    url: '/dp/getSignature',
    method: 'post',
    data: {
      ...params,
    },
  });
};
//获取用户基本信息
export const baseInformation = (params) => {
  return request({
    url: '/dp/whPlatform/baseInformation',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//获取实时数据
export const dynamicMonitoring = (params) => {
  return request({
    url: '/dp/whPlatform/dynamicMonitoring',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//获取日晨午检
export const dayLeaveCheckReport = (params) => {
  return request({
    url: '/dp/whPlatform/dayLeaveCheckReport',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//获取日请假
export const dayLeaveReport = (params) => {
  return request({
    url: '/dp/whPlatform/dayLeaveReport',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//获取基础数据
export const comprehensiveDataReport = (params) => {
  return request({
    url: '/dp/whPlatform/comprehensiveDataReport',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//获取日传染病分布
export const dayInfectiousDiseaseData = (params) => {
  return request({
    url: '/dp/whPlatform/dayInfectiousDiseaseData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//获取日常规病排名
export const dayConventionalDiseaseData = (params) => {
  return request({
    url: '/dp/whPlatform/dayConventionalDiseaseData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//获取日症状情况
export const daySymptomData = (params) => {
  return request({
    url: '/dp/whPlatform/daySymptomData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//晨午检完成趋势
export const stuLeaveCompletionTrendData = (params) => {
  return request({
    url: '/dp/whPlatform/stuLeaveCompletionTrendData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//出勤情况趋势
export const attendanceTrendsData = (params) => {
  return request({
    url: '/dp/whPlatform/attendanceTrendsData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//近一周传染病趋势
export const diseaseTrendData = (params) => {
  return request({
    url: '/dp/whPlatform/diseaseTrendData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//近一周病情趋势
export const conventionalDiseaseTrendsData = (params) => {
  return request({
    url: '/dp/whPlatform/conventionalDiseaseTrendsData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//近一周病症趋势
export const symptomDataTrendsData = (params) => {
  return request({
    url: '/dp/whPlatform/symptomDataTrendsData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//基本信息弹窗
export const popBaseInfoData = (params) => {
  return request({
    url: '/dp/whPlatform/popBaseInfoData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//晨午检弹窗
export const popStuleaveData = (params) => {
  return request({
    url: '/dp/whPlatform/popStuleaveData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//请假弹窗
export const leaveData = (params) => {
  return request({
    url: '/dp/whPlatform/popLeaveData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//近一周传染病弹窗
export const popDiseaseData = (params) => {
  return request({
    url: '/dp/whPlatform/popDiseaseData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//常规病弹窗
export const conventionalDiseaseData = (params) => {
  return request({
    url: '/dp/whPlatform/popConventionalDiseaseData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//症状弹窗
export const popSymptomData = (params) => {
  return request({
    url: '/dp/whPlatform/popSymptomData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//学校应检班级
export const schoolShouldCheckClassData = (params) => {
  return request({
    url: '/dp/whPlatform/schoolShouldCheckClassData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//学校已检班级
export const schoolCheckedClassData = (params) => {
  return request({
    url: '/dp/whPlatform/schoolCheckedClassData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//学校请假
export const schoolLeaveData = (params) => {
  return request({
    url: '/dp/whPlatform/schoolLeaveData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//学校综合数据
export const schoolSynthesizeData = (params) => {
  return request({
    url: '/dp/whPlatform/schoolSynthesizeData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//学校传染病分布
export const schoolDiseaseData = (params) => {
  return request({
    url: '/dp/whPlatform/schoolDiseaseData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//学校常规病
export const schoolConventionalDiseaseData = (params) => {
  return request({
    url: '/dp/whPlatform/schoolConventionalDiseaseData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//学校症状
export const schoolSymptomDataData = (params) => {
  return request({
    url: '/dp/whPlatform/schoolSymptomData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//学校晨午检完成趋势
export const schoolStuLeaveCheckTrendData = (params) => {
  return request({
    url: '/dp/whPlatform/schoolStuLeaveCheckTrendData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//学校出勤情况
export const schoolAttendanceTrendsData = (params) => {
  return request({
    url: '/dp/whPlatform/schoolAttendanceTrendsData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//学校学生
export const schoolStuLeaveInfoData = (params) => {
  return request({
    url: '/dp/whPlatform/schoolStuLeaveInfoData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//年级
export const schoolGradeTabulationData = (params) => {
  return request({
    url: '/dp/whPlatform/schoolGradeTabulationData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//班级
export const schoolClassTabulationData = (params) => {
  return request({
    url: '/dp/whPlatform/schoolClassTabulationData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//学生信息
export const schoolStudentInfoData = (params) => {
  return request({
    url: '/dp/whPlatform/schoolStudentInfoData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};

//学校近一周传染病趋势
export const schoolDiseaseTrendsData = (params) => {
  return request({
    url: '/dp/whPlatform/schoolDiseaseTrendsData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//学校近一周病症趋势
export const schoolSymptomTrendsData = (params) => {
  return request({
    url: '/dp/whPlatform/schoolSymptomTrendsData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
//学校近一周普通病趋势
export const schoolIllnessTrendsData = (params) => {
  return request({
    url: '/dp/whPlatform/schoolIllnessTrendsData',
    method: 'post',
    headers: {
      signature: params.header,
    },
    data: {
      ...params,
    },
  });
};
