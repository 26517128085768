<template>
  <el-dialog
    custom-class="way"
    append-to-body
    :width="width"
    :visible.sync="visible"
    :title="title"
    class="new-dialog"
    @close="handleClose">
    <div v-if="type == 1">
      <div class="operation">
        <div>
          <div class="title">学校名称</div>
          <el-input
            placeholder="请输入查询"
            v-model="queryBase.schoolName"
            @change="getBase()"></el-input>
        </div>
        <div>
          <div
            class="pointer"
            @click="
              queryBase.orderType == 'teacher'
                ? (queryBase.orderType = 'student')
                : (queryBase.orderType = 'teacher'),
                getBase()
            ">
            {{ queryBase.orderType == 'teacher' ? '老师排序 ▼' : '学生排序 ▼' }}
          </div>
          <el-divider direction="vertical"></el-divider>
          <div
            class="pointer"
            @click="
              queryBase.orderFlag == 0
                ? (queryBase.orderFlag = 1)
                : (queryBase.orderFlag = 0),
                getBase(queryBase.orderFlag)
            ">
            {{ queryBase.orderFlag == 0 ? '降序 ▲' : '升序 ▼' }}
          </div>
        </div>
      </div>
      <el-table
        v-loading="loading"
        class="my-table"
        :data="baseInfo"
        height="46rem"
        :cell-style="cellStyle"
        @row-click="schoolClick"
        :header-cell-style="{
          background: '#e3f2ff',
          color: '#09246B',
          fontSize: '2.8rem',
          height: '3.8rem',
        }">
        <el-table-column
          prop="SCHOOL_NAME"
          label="学校名称"
          width="410"></el-table-column>
        <el-table-column
          prop="period"
          label="学段"></el-table-column>
        <el-table-column
          prop="studentNumber"
          label="学生人数"></el-table-column>
        <el-table-column
          prop="teacherNumber"
          label="老师人数"></el-table-column>
      </el-table>
    </div>
    <div v-if="type == 2">
      <div class="operation">
        <div>
          <div class="title">学校名称</div>
          <el-input
            placeholder="请输入查询"
            v-model="query.schoolName"
            @change="getcheckNums()"></el-input>
        </div>
        <div>
          <!-- <div
            class="pointer"
            @click="
              query.type == 'wu'
                ? (query.type = 'wan')
                : query.type == 'wan'
                ? (query.type = '')
                : (query.type = 'wu'),
                getcheckNums()
            ">
            {{
              query.type == 'wu'
                ? '午检顺序 ▼'
                : query.type == 'wan'
                ? '晚检顺序 ▼'
                : '晨检顺序 ▼'
            }}
          </div> 
          <el-divider direction="vertical"></el-divider>-->
          <div
            class="pointer"
            @click="
              query.orderFlag == 0
                ? (query.orderFlag = 1)
                : (query.orderFlag = 0),
                getcheckNums()
            ">
            {{ query.orderFlag == 0 ? '降序 ▲' : '升序 ▼' }}
          </div>
        </div>
      </div>
      <el-table
        v-loading="loading"
        class="my-table"
        :data="cwj"
        height="46rem"
        :cell-style="cellStyle"
        @row-click="schoolClick"
        :header-cell-style="{ background: '#e3f2ff', color: '#09246B' }">
        <el-table-column
          prop="COMPANY_NAME"
          label="学校名称"
          width="240"></el-table-column>
        <el-table-column
          prop="period"
          label="学段"></el-table-column>
        <el-table-column
          prop="inspectorInfo"
          align="center"
          label="健康监测员"
          width="260"></el-table-column>
        <el-table-column
          align="center"
          prop="checked_num"
          label="晨检">
          <template slot-scope="scope">
            <span>
              {{
                scope.row.check_type_name == '晨检' ? scope.row.checked_num : 0
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="wuNums"
          align="center"
          label="午检">
          <template slot-scope="scope">
            <span>
              {{
                scope.row.check_type_name == '午检' ? scope.row.checked_num : 0
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="wanNums"
          align="center"
          label="晚检">
          <template slot-scope="scope">
            <span>
              {{
                scope.row.check_type_name == '晚检' ? scope.row.checked_num : 0
              }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="type == 3">
      <div class="operation">
        <div>
          <div class="title">学校名称</div>
          <el-input
            placeholder="请输入查询"
            v-model="queryLeave.schoolName"
            @change="getleaveNums()"></el-input>
        </div>
        <div>
          <div
            class="pointer"
            @click="
              queryLeave.orderType == 'illness'
                ? (queryLeave.orderType = 'sick')
                : queryLeave.orderType == 'sick'
                ? (queryLeave.orderType = 'infection')
                : queryLeave.orderType == 'infection'
                ? (queryLeave.orderType = 'leave')
                : (queryLeave.orderType = 'illness'),
                getleaveNums()
            ">
            {{
              queryLeave.orderType == 'sick'
                ? '病假排序 ▼'
                : queryLeave.orderType == 'infection'
                ? '传染病顺序 ▼'
                : queryLeave.orderType == 'illness'
                ? '普通病顺序 ▼'
                : '请假顺序 ▼'
            }}
          </div>
          <el-divider direction="vertical"></el-divider>
          <div
            class="pointer"
            @click="
              queryLeave.orderFlag == 0
                ? (queryLeave.orderFlag = 1)
                : (queryLeave.orderFlag = 0),
                getleaveNums()
            ">
            {{ queryLeave.orderFlag == 0 ? '降序 ▲' : '升序 ▼' }}
          </div>
        </div>
      </div>
      <el-table
        v-loading="loading"
        class="my-table"
        :data="qj"
        height="46rem"
        :cell-style="cellStyle"
        @row-click="schoolClick"
        :header-cell-style="{ background: '#e3f2ff', color: '#09246B' }">
        <el-table-column
          prop="company_name"
          label="学校名称"
          width="240"></el-table-column>
        <el-table-column
          prop="should_num"
          align="center"
          label="应到人数"></el-table-column>
        <el-table-column
          align="center"
          prop="leave_num"
          label="请假人数"></el-table-column>
        <el-table-column
          prop="rateOfLeave"
          align="center"
          label="请假率"></el-table-column>
        <el-table-column
          prop="sick_leave_num"
          align="center"
          label="病假人数"></el-table-column>
        <el-table-column
          prop="rateOfLeaveSick"
          align="center"
          label="病假率"></el-table-column>
        <el-table-column
          prop="infection_num"
          align="center"
          label="传染病"></el-table-column>
        <el-table-column
          prop="illness_num"
          align="center"
          label="普通疾病"></el-table-column>
      </el-table>
    </div>
    <div v-if="type == 4">
      <div class="operation">
        <div>
          <div class="title">学校名称</div>
          <el-input
            placeholder="请输入查询"
            v-model="query.schoolName"
            @change="getIllNums()"></el-input>
        </div>
        <div>
          <div
            class="pointer"
            @click="
              query.orderFlag == 0
                ? (query.orderFlag = 1)
                : (query.orderFlag = 0),
                getIllNums()
            ">
            {{ query.orderFlag == 0 ? '降序 ▲' : '升序 ▼' }}
          </div>
        </div>
      </div>
      <el-table
        v-loading="loading"
        class="my-table"
        :data="crbfb"
        height="240px"
        :cell-style="cellStyle"
        @row-click="schoolClick"
        :header-cell-style="{ background: '#e3f2ff', color: '#09246B' }">
        <el-table-column
          prop="SCHOOL_NAME"
          label="学校名称"
          width="270"></el-table-column>
        <el-table-column
          prop="period"
          align="center"
          label="学段"></el-table-column>
        <el-table-column
          align="center"
          prop="illnessNumber"
          label="学生人数"></el-table-column>
      </el-table>
    </div>
    <div v-if="type == 5">
      <div class="operation">
        <div>
          <div class="title">学校名称</div>
          <el-input
            placeholder="请输入查询"
            v-model="query.schoolName"
            @change="getSymNums()"></el-input>
        </div>
        <div>
          <div
            class="pointer"
            @click="
              query.orderFlag == 0
                ? (query.orderFlag = 1)
                : (query.orderFlag = 0),
                getSymNums()
            ">
            {{ query.orderFlag == 0 ? '降序 ▲' : '升序 ▼' }}
          </div>
        </div>
      </div>
      <el-table
        v-loading="loading"
        class="my-table"
        :data="cgb"
        height="240px"
        :cell-style="cellStyle"
        @row-click="schoolClick"
        :header-cell-style="{ background: '#e3f2ff', color: '#09246B' }">
        <el-table-column
          prop="school_name"
          label="学校名称"
          width="270"></el-table-column>
        <el-table-column
          prop="period"
          align="center"
          label="学段"></el-table-column>
        <el-table-column
          align="center"
          prop="illnessNumber"
          label="学生人数"></el-table-column>
      </el-table>
    </div>
    <div v-if="type == 6">
      <div class="operation">
        <div>
          <div class="title">学校名称</div>
          <el-input
            placeholder="请输入查询"
            v-model="query.schoolName"
            @change="getSymptom()"></el-input>
        </div>
        <div>
          <div
            class="pointer"
            @click="
              query.orderFlag == 0
                ? (query.orderFlag = 1)
                : (query.orderFlag = 0),
                getSymptom()
            ">
            {{ query.orderFlag == 0 ? '降序 ▲' : '升序 ▼' }}
          </div>
        </div>
      </div>
      <el-table
        v-loading="loading"
        class="my-table"
        :data="zz"
        height="240px"
        :cell-style="cellStyle"
        @row-click="schoolClick"
        :header-cell-style="{ background: '#e3f2ff', color: '#09246B' }">
        <el-table-column
          prop="school_name"
          label="学校名称"
          width="270"></el-table-column>
        <el-table-column
          prop="period"
          align="center"
          label="学段"></el-table-column>
        <el-table-column
          align="center"
          prop="illnessNumber"
          label="学生人数"></el-table-column>
      </el-table>
    </div>
    <div
      v-if="type == 7"
      v-loading="loading"
      class="personal">
      <div class="l">
        <div class="info">
          <img src="../../../assets/statisitics/管理员女@2x.png" />
          <div>
            <span
              style="
                color: #09246b;
                font-size: 3.4rem;
                font-family: PingFangSC;
              ">
              {{ studentInfo['学生'] }}
              <!-- {{ datas.info.student_name }} -->
            </span>
            {{ studentInfo['年级'] }}
            <!-- {{ datas.info.grade_name }}{{ datas.info.class_name }}班 -->
          </div>
          <div>
            <span class="name">家长电话：</span>
            {{ studentInfo['联系电话'] }}
          </div>
          <div>
            <span class="name">请假时间：</span>
            -
          </div>

          <div>
            <span class="name">离校状态：</span>
            {{ studentInfo['离校标志'] }}
          </div>
          <div>
            <span class="name">健康状态：</span>
            {{ studentInfo['健康状态'] }}
          </div>
        </div>
      </div>

      <div class="r">
        <div>
          <span class="name">性别：</span>
          {{
            studentInfo['性别']
          }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span class="name">年龄：</span>
          {{ studentInfo['年龄'] }}
        </div>
        <div>
          <span class="name">疾病名称：</span>
          {{ studentInfo['疾病名称'] }}
        </div>
        <div>
          <span class="name">就诊类型：</span>
          {{ studentInfo['就诊类型'] }}
        </div>
        <div>
          <span class="name">就诊去向：</span>
          {{ studentInfo['就诊去向'] }}
        </div>
        <div>
          <span class="name">症状：</span>
          {{ studentInfo['症状'] }}
        </div>
        <div>
          <span class="name">体温：</span>
          {{ studentInfo['体温'] }}
        </div>
        <div>
          <span class="name">核酸/抗原检测：</span>
          {{ studentInfo['核酸/抗原检测'] }}
        </div>
        <div>
          <span class="name">就诊/转归情况：</span>
          {{ studentInfo['就诊/转归情况'] }}
        </div>
        <div>
          <span class="name">家庭住址：</span>
          {{ studentInfo['家庭地址"'] }}
        </div>
        <div>
          <span class="name">备注：</span>
          {{ studentInfo['申请备注'] }}
        </div>
        <div>
          <span class="name">返校时间：</span>
          {{ studentInfo['返校日期'] }}
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
// import * as echarts from 'echarts';
/** 临时数据 */
// import datas from './../school.json';
// import student from './../studentInfo.json';
// import rcwj from './../rcwj.json';
// import qj from './../qj.json';
//import  getBase,
// getcheckNums,
// getleaveNums,
// getIllNums,
// getSymNums,
// getStudent,
// getToday,
// cheWeek,
// symWeek,
// illWeek,
// queWeek,
//'@/api/statisitics/index';
import {
  popBaseInfoData,
  popStuleaveData,
  leaveData,
  popDiseaseData,
  conventionalDiseaseData,
  schoolStudentInfoData,
  popSymptomData,
} from '@/api/statisitics/yzt';
import dayjs from 'dayjs';
export default {
  data() {
    return {
      loading: false,
      visible: true,
      queryBase: {
        orderType: 'student',
        orderFlag: 0,
      },
      queryLeave: {
        orderType: 'leave',
        orderFlag: 0,
      },
      query: {
        orderFlag: 0,
      },
      baseInfo: [], //学校信息
      crbfb: [], //传染病分布
      cwj: [], //晨午检
      cgb: [], //常规病
      zz: [], //症状
      qj: [],
      studentInfo: [
        { '学生': '' },
        { '年级': '' },
        { '联系电话': '' },
        { '离校标志': '' },
        { '健康状态': '' },
        { '性别': '' },
        { '年龄': '' },
        { '疾病名称': '' },
        { '就诊类型': '' },
        { '就诊去向': '' },
        { '症状': '' },
        { '体温': '' },
        { '核酸/抗原检测': '' },
        { '就诊/转归情况': '' },
        { '家庭地址': '' },
        { '申请备注': '' },
        { '返校日期': '' },
      ], //学生信息
      // student,
      // studentInfo: '',
      temp: '',
    };
  },
  props: {
    info: {},
    title: {
      default: '',
      type: String,
    },
    dialogVisible: {},
    type: {},
    config: {},

    width: {
      type: String,
      default: '50%',
    },
  },
  components: {},
  watch: {
    dialogVisible: {
      handler: function (val) {
        this.query = {
          orderFlag: 0,
        };
        this.queryBase = {
          orderType: 'student',
          orderFlag: 0,
        };
        this.queryLeave = {
          orderType: 'leave',
          orderFlag: 0,
        };
        this.visible = val;
      },
      immediate: true,
    },
  },
  methods: {
    getDay(day) {
      var today = new Date();

      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;

      today.setTime(targetday_milliseconds); //注意，这行是关键代码

      var tYear = today.getFullYear();

      var tMonth = today.getMonth();

      var tDate = today.getDate();

      tMonth = this.doHandleMonth(tMonth + 1);

      tDate = this.doHandleMonth(tDate);

      return tYear + '-' + tMonth + '-' + tDate;
    },

    doHandleMonth(month) {
      var m = month;

      if (month.toString().length == 1) {
        m = '0' + month;
      }

      return m;
    },
    //判断本周第一天和最后一天
    getFirstDayAndLastDay() {
      // let date = new Date();
      // var weekday = date.getDay() || 7;
      // let monday = date.setDate(date.getDate() - weekday + 1);
      // let sunday = '';
      // if (weekday < 6) {
      //   sunday = new Date();
      // } else {
      //   sunday = new Date(
      //     date.getTime() +
      //       3600 * 1000 * 24 * (date.getDay() == 0 ? 0 : 7 - date.getDay() - 2)
      //   );
      // }
      return {
        startDate: this.getDay(-6),
        endDate: this.getDay(0),
        // startDate: dayjs(monday).format('YYYY-MM-DD'),
        // endDate: dayjs(sunday).format('YYYY-MM-DD'),
      };
    },
    //获取基础信息
    async getBase() {
      this.loading = true;
      //基本信息弹窗
      const { data } = await popBaseInfoData({
        ...this.config,
        timestamp: new Date().getTime(),
        date: this.getFirstDayAndLastDay().endDate,
        ...this.queryBase,
      });
      this.baseInfo = eval(data);
      this.loading = false;
      //console.log(data);
    },
    //3月17日晨午检
    async getcheckNums() {
      this.loading = true;
      const { data } = await popStuleaveData({
        ...this.config,
        timestamp: new Date().getTime(),
        date: this.getFirstDayAndLastDay().endDate,
        ...this.query,
      });
      this.cwj = eval(data);
      this.loading = false;
    },
    //3月17日请假
    async getleaveNums() {
      this.loading = true;
      const { data } = await leaveData({
        ...this.config,
        timestamp: new Date().getTime(),
        date: this.getFirstDayAndLastDay().endDate,
        ...this.queryLeave,
      });
      this.qj = eval(data);
      this.loading = false;
    },
    //传染病分布统计
    async getIllNums(name) {
      if (name) {
        this.temp = name;
      }
      this.loading = true;
      const { data } = await popDiseaseData({
        ...this.config,
        ...this.query,
        timestamp: new Date().getTime(),
        startDate: this.getFirstDayAndLastDay().endDate,
        endDate: this.getFirstDayAndLastDay().endDate,
        illnessName: this.temp,
      });
      this.crbfb = eval(data);
      this.loading = false;
    },
    //常规病统计
    async getSymNums(name) {
      if (name) {
        this.temp = name;
      }
      this.loading = true;
      const { data } = await conventionalDiseaseData({
        ...this.config,
        timestamp: new Date().getTime(),
        startDate: this.getFirstDayAndLastDay().endDate,
        endDate: this.getFirstDayAndLastDay().endDate,
        ...this.query,
        illnessName: this.temp,
      });
      this.cgb = eval(data);
      this.loading = false;
    },
    //症状统计
    async getSymptom(name) {
      if (name) {
        this.temp = name;
      }
      this.loading = true;
      const { data } = await popSymptomData({
        ...this.config,
        timestamp: new Date().getTime(),
        startDate: this.getFirstDayAndLastDay().endDate,
        endDate: this.getFirstDayAndLastDay().endDate,
        ...this.query,
        illnessName: this.temp,
      });
      this.zz = eval(data);
      this.loading = false;
    },
    cellStyle({ columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1) {
        return 'color:#09246B ';
      }
      return '';
    },
    //学校详情
    schoolClick(row) {
      console.log(row);
      this.$router.push({
        path: '/statistics/school',
        query: {
          id: row.SCHOOL_CODE
            ? row.SCHOOL_CODE
            : row.COMPANY_CODE
            ? row.COMPANY_CODE
            : row.school_code,
          name: row.SCHOOL_NAME
            ? row.SCHOOL_NAME
            : row.COMPANY_NAME
            ? row.COMPANY_NAME
            : row.school_name,
        },
      });
    },
    //学生信息
    async getStudentInfo(id, type) {
      this.loading = true;
      const { data } = await schoolStudentInfoData({
        ...this.config,
        studentId: id,
        startDate: this.getFirstDayAndLastDay().endDate,
        endDate: this.getFirstDayAndLastDay().endDate,
        timestamp: new Date().getTime(),
      });
      if (eval(data).length > 0) {
        this.studentInfo = eval(data)[0];
      } else {
        console.log(2334333);
        for (var key in this.studentInfo) {
          this.studentInfo[key] = null;
        }
      }

      // let index = type == '晨' ? 0 : type == '午' ? 1 : 2;
      // let res = this.student.filter((item) => item.xsid == id);
      // if (res.length > 1) {
      //   this.studentInfo = res[index];
      // } else {
      //   this.studentInfo = res[0];
      // }

      //let datas = [];
      // let base = await getStudent({ studentId: id });
      // let today = await getToday({ studentId: id });
      // let cheWeeks = await cheWeek({ studentId: id });
      // let symWeeks = await symWeek({ studentId: id });
      // let illWeeks = await illWeek({ studentId: id });
      // let queWeeks = await queWeek({ studentId: id });

      // datas.info = base[0];
      // datas.today = today;
      // datas.cheWeeks = cheWeeks;
      // let newArr1 = [];
      // for (var arr1 in symWeeks) {
      //   newArr1.push({
      //     value: symWeeks[arr1],
      //     name: arr1,
      //   });
      // }
      // datas.symWeeks = newArr1;
      // let newArr = [];
      // for (var arr in illWeeks) {
      //   newArr.push({
      //     value: illWeeks[arr],
      //     name: arr,
      //   });
      // }

      // datas.illWeeks = newArr;
      // datas.queWeeks = queWeeks;
      // console.log(datas);
      // this.datas = datas;
      // this.$nextTick(() => {
      //   this.initJyzhbqk();
      //   this.initJyzqkqk();
      //   this.initJyzbzqk();
      //   this.initJyzcyjc();
      // });
      this.loading = false;
    },
    //近一周缺课
    // initJyzqkqk() {
    //   let chartColumn = echarts.init(this.$refs.jyzqkqk);
    //   let option = {
    //     tooltip: {
    //       trigger: 'item',
    //       confine: true,
    //     },
    //     legend: {
    //       top: '30%',
    //       left: 'right',
    //       itemHeight: 10,
    //       itemWidth: 10,
    //       orient: 'vertical',
    //       formatter: function (name) {
    //         var data = option.series[0].data; //获取series中的data
    //         var total = 0;
    //         var tarValue;
    //         for (var i = 0, l = data.length; i < l; i++) {
    //           total += data[i].value;
    //           if (data[i].name == name) {
    //             tarValue = data[i].value;
    //           }
    //         }
    //         var p = (tarValue / total) * 100;
    //         return name + ' ' + ' ' + p.toFixed(0) + '%';
    //       },
    //     },
    //     series: [
    //       {
    //         name: '近一周缺课情况',
    //         type: 'pie',
    //         center: ['40%', '45%'],
    //         radius: ['43%', '50%'],
    //         avoidLabelOverlap: false,
    //         label: {
    //           show: false,
    //           position: 'center',
    //         },
    //         emphasis: {
    //           label: {
    //             show: true,
    //             fontSize: 30,
    //             fontWeight: 'bold',
    //           },
    //         },
    //         labelLine: {
    //           show: false,
    //         },
    //         data: [
    //           { name: '正常', value: this.datas.queWeeks[0].nums },
    //           { name: '事假', value: this.datas.queWeeks[0].shiNums },
    //           { name: '病假', value: this.datas.queWeeks[0].illNums },
    //         ],
    //       },
    //     ],
    //   };
    //   chartColumn.setOption(option);
    // },
    //近一周患病
    // initJyzhbqk() {
    //   let chartColumn = echarts.init(this.$refs.jyzhbqk);
    //   let option = {
    //     tooltip: {
    //       trigger: 'item',
    //       confine: true,
    //     },
    //     legend: {
    //       top: '30%',
    //       left: 'right',
    //       itemHeight: 10,
    //       itemWidth: 10,
    //       orient: 'vertical',
    //       formatter: function (name) {
    //         var data = option.series[0].data; //获取series中的data
    //         var total = 0;
    //         var tarValue;
    //         for (var i = 0, l = data.length; i < l; i++) {
    //           total += data[i].value;
    //           if (data[i].name == name) {
    //             tarValue = data[i].value;
    //           }
    //         }
    //         var p = (tarValue / total) * 100;
    //         return name + ' ' + ' ' + p.toFixed(0) + '%';
    //       },
    //     },
    //     series: [
    //       {
    //         name: '近一周患病情况',
    //         type: 'pie',
    //         center: ['40%', '45%'],
    //         radius: ['43%', '50%'],
    //         avoidLabelOverlap: false,
    //         label: {
    //           show: false,
    //           position: 'center',
    //         },
    //         emphasis: {
    //           label: {
    //             show: true,
    //             fontSize: 30,
    //             fontWeight: 'bold',
    //           },
    //         },
    //         labelLine: {
    //           show: false,
    //         },
    //         data: this.datas.illWeeks,
    //       },
    //     ],
    //   };
    //   chartColumn.setOption(option);
    // },
    //近一周病症
    // initJyzbzqk() {
    //   let chartColumn = echarts.init(this.$refs.jyzbzqk);
    //   let option = {
    //     tooltip: {
    //       trigger: 'item',
    //       confine: true,
    //     },
    //     legend: {
    //       top: '30%',
    //       left: 'right',
    //       itemHeight: 10,
    //       itemWidth: 10,
    //       orient: 'vertical',
    //       formatter: function (name) {
    //         var data = option.series[0].data; //获取series中的data
    //         var total = 0;
    //         var tarValue;
    //         for (var i = 0, l = data.length; i < l; i++) {
    //           total += data[i].value;
    //           if (data[i].name == name) {
    //             tarValue = data[i].value;
    //           }
    //         }
    //         var p = (tarValue / total) * 100;
    //         return name + ' ' + ' ' + p.toFixed(0) + '%';
    //       },
    //     },
    //     series: [
    //       {
    //         name: '近一周病症情况',
    //         type: 'pie',
    //         center: ['40%', '45%'],
    //         radius: ['43%', '50%'],
    //         avoidLabelOverlap: false,
    //         label: {
    //           show: false,
    //           position: 'center',
    //         },
    //         emphasis: {
    //           label: {
    //             show: true,
    //             fontSize: 30,
    //             fontWeight: 'bold',
    //           },
    //         },
    //         labelLine: {
    //           show: false,
    //         },
    //         data: this.datas.symWeeks,
    //       },
    //     ],
    //   };
    //   chartColumn.setOption(option);
    // },
    //近一周参与检测情况
    // initJyzcyjc() {
    //   let chartColumn = echarts.init(this.$refs.yzcyjc);
    //   let option = {
    //     xAxis: {
    //       type: 'category',
    //       data: ['晨检', '午检', '晚检'],
    //     },
    //     yAxis: {
    //       type: 'value',
    //       axisLine: {
    //         show: true,
    //       },
    //     },
    //     legend: {
    //       show: false,
    //       left: 'right',
    //       itemHeight: 8,
    //       itemWidth: 8,
    //     },
    //     tooltip: {
    //       show: true,
    //       confine: true,
    //     },
    //     grid: {
    //       left: '2%',
    //       top: '17%',
    //       right: '6%',
    //       bottom: '3%',
    //       containLabel: true,
    //     },
    //     series: [
    //       {
    //         data: [
    //           this.datas.cheWeeks[0].chNums,
    //           this.datas.cheWeeks[0].wuNums,
    //           this.datas.cheWeeks[0].wanNums,
    //         ],
    //         type: 'bar',
    //         name: '一周参与情况',
    //         barWidth: 17,

    //         itemStyle: {
    //           color: '#7CB8FF',
    //           barBorderRadius: [2, 2, 0, 0],
    //         },
    //       },
    //     ],
    //   };
    //   chartColumn.setOption(option);
    // },
    handleClose() {
      this.$emit('dialogClose', true);
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: Rajdhani;
  src: url('../../../assets/fonts/站酷仓耳渔阳体-W05.ttf');
}

@font-face {
  font-family: DS-Digital;
  src: url('../../../assets/fonts/DS-DIGIB-2.ttf');
}

@font-face {
  font-family: PingFangSC;
  src: url('../../../assets/fonts/苹方黑体-中粗-简.ttf');
}

@font-face {
  font-family: YouSheBiaoTiHei;
  src: url('../../../assets/fonts/YouSheBiaoTiHei-2.ttf');
}
.el-dialog__wrapper {
  transition-duration: 0.4s;
}
.dialog-fade-enter-active {
  animation: none !important;
}
.dialog-fade-leave-active {
  transition-duration: 0.15s !important;
  animation: none !important;
}

.dialog-fade-enter-active .el-dialog,
.dialog-fade-leave-active .el-dialog {
  animation-fill-mode: forwards;
}

.dialog-fade-enter-active .el-dialog {
  animation-duration: 0.4s;
  animation-name: anim-open;
  animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
}

.dialog-fade-leave-active .el-dialog {
  animation-duration: 0.4s;
  animation-name: anim-close;
}

@keyframes anim-open {
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  100% {
    transform: none;
  }
}
@keyframes anim-close {
  0% {
    transform: none;
  }
  60% {
    transform: translate3d(-5px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
}

.personal {
  background: linear-gradient(180deg, #e8f5ff, #ffffff);
  border-radius: 0.4rem;
  padding: 3rem;
  display: flex;
  .l {
    flex: 1;
    position: relative;
    padding-right: 5rem;
  }
  .l::after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -12rem;
    right: 0;
    height: 24rem;
    opacity: 0.4;
    border-left: 2px solid #09246b;
  }
  .r {
    width: 60%;
    color: #09246b;
    .name {
      opacity: 0.6;
    }
    line-height: 5rem;
    padding-left: 5rem;
  }

  .name {
    width: 12rem;
    opacity: 0.6;
  }

  .title {
    color: #09246b;
    font-size: 5rem;
  }
  .info {
    color: #09246b;
    font-size: 3rem;
    line-height: 5rem;
    img {
      width: 9.7rem;
      margin-top: 0.8rem;
      margin-left: 10%;
    }
  }
}
.operation {
  display: flex;
  font-family: PingFangSC;
  justify-content: space-between;
  padding: 1.5rem 0;
  color: #09246b;
  .info {
    border: 1px #000 solid;
  }
  > div {
    align-items: center;
    display: flex;
  }
  .title {
    width: 18rem;
  }
}
body.el-table th.gutter {
  /*解决el-table加了gutter后 边框出现白边*/
  display: table-cell !important;
}
.el-table th.gutter {
  // display: none;
  background-color: #e3f2ff;
  width: 0;
}

// .el-table colgroup col[name='gutter'] {
//   display: none;

//   width: 0;
// }
.my-table {
  .cell {
    font-size: 2.8rem;
    line-height: 3rem !important;
  }
  .el-table__body {
    width: 100% !important;
    padding-right: 1rem;
    font-size: 2.6rem;
  }
  // 滚动条样式
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #4179ff;
  }
  ::-webkit-scrollbar-track {
    background: #cddeff;
  }
  ::-webkit-scrollbar-thumb {
    background: #4179ff;
    border-radius: 10px;
  }
}

@font-face {
  font-family: Rajdhani;
  src: url('../../../assets/fonts/站酷仓耳渔阳体-W05.ttf');
}

@font-face {
  font-family: DS-Digital;
  src: url('../../../assets/fonts/DS-DIGIB-2.ttf');
}

@font-face {
  font-family: PingFangSC;
  src: url('../../../assets/fonts/苹方黑体-中粗-简.ttf');
}

@font-face {
  font-family: YouSheBiaoTiHei;
  src: url('../../../assets/fonts/YouSheBiaoTiHei-2.ttf');
}
.new-dialog {
  .el-dialog {
    border-radius: 10px;
    padding: 3px;
  }
  .el-dialog__body {
    font-size: 2.8rem;
    padding: 0 20px 30px 20px;
    background: linear-gradient(180deg, #e8f5ff 0%, #ffffff 100%);
  }
  .el-dialog__header {
    background: url('../../../assets/statisitics/Group 1460@2x.png');
    background-size: 100% 100%;
    border-radius: 10px;
    text-align: center;
    .el-dialog__title {
      font-family: YouSheBiaoTiHei;
      font-size: 5rem;
      color: #fff;
    }

    .el-dialog__close {
      color: #4179ff;
      font-weight: bold;
      border-radius: 50%;
      padding: 3px;
      background-color: #fff;
    }
  }
}
</style>
