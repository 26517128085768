<template>
  <div>
    <div class="title">
      <img :src="icon1" v-show="type==1"/>
      <img :src="icon2" v-show="type==2"/>
      <img :src="icon3" v-show="type==3"/>
      <img :src="icon4" v-show="type==4"/>
      <img :src="icon5" v-show="type==5"/>
      <img :src="icon6" v-show="type==6"/>
      <img :src="icon7" v-show="type==7"/>
      <img :src="icon8" v-show="type==8"/>
      <img :src="icon9" v-show="type==9"/>
      <img :src="icon10" v-show="type==10"/>
      <img :src="icon11" v-show="type==11"/>
      <img :src="icon12" v-show="type==12"/>
      <div class="title">{{ title }}</div>
      <!-- <img src="../../../assets/statisitics/Group 1386@2x.png" style="text-align: right;"/> -->
    </div>
  </div>
</template>

<script>
  import icon1 from "@/assets/statisitics/1.png";
  import icon2 from "@/assets/statisitics/2.png";
  import icon3 from "@/assets/statisitics/3.png";
  import icon4 from "@/assets/statisitics/4.png";
  import icon5 from "@/assets/statisitics/5.png";
  import icon6 from "@/assets/statisitics/6.png";
  import icon7 from "@/assets/statisitics/7.png";
  import icon8 from "@/assets/statisitics/8.png";
  import icon9 from "@/assets/statisitics/9.png";
  import icon10 from "@/assets/statisitics/10.png";
  import icon11 from "@/assets/statisitics/11.png";
  import icon12 from "@/assets/statisitics/12.png";
  export default {
    name: 'Title',
    data(){
      return{
        icon1,
        icon2,
        icon3,
        icon4,
        icon5,
        icon6,
        icon7,
        icon8,
        icon9,
        icon10,
        icon11,
        icon12,
      }
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: "1"
      }
    }
  }
</script>
<style scoped lang="scss">
  @font-face{
      font-family: YouSheBiaoTiHei-2;
      src: url("../../../assets/fonts/YouSheBiaoTiHei-2.ttf");
  }
  @font-face{
      font-family: PingFangSC;
      src: url("../../../assets/fonts/苹方黑体-中粗-简.ttf");
  }
  .title {
   background: linear-gradient(89deg,#e0f0ff, #e0f0ff, rgba(224,240,255,0.00) 100%);
  height: 7rem;
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 3.2rem;
    // padding: 5px;
    color: #09246B;
    font-family:PingFangSC;
    border-radius: 2rem;
    img{
      width: 5.4rem;
      margin:0 1rem 0 2rem;
      // height: 16px;
      // margin: 0 6px;
    }
  }
</style>
